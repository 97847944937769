<template>
  <div>
    <div class="content-header">
      <div class="title-1">
        <router-link to="/registration"
          ><img src="../assets/back_arrow.svg" /><span style="color: #44D62C;"
            >Regristration or login</span
          ></router-link
        >
      </div>
    </div>

    <div class="single-games">
      <div class="forgot-pass-1">
        <div id="errorMessage" style="color:red;">&nbsp;</div>
        <form action="#">
          <div class="content-forgot">
            <div id="content1">
              <input
                type="number"
                class="form-control"
                placeholder="Phone Number"
                v-model="phoneNumber"
              />
            </div>
          </div>
          <button
            type="button"
            class="submit-btn1"
            @click="passwordRecovery"
            id="phoneNumber"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
    <footer>
      <div class="main-footer">
        <div class="social-icon"></div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ForgotPass",
  data: () => {
    return {
      host: null,
      name: "",
      phone: localStorage.getItem("phone"),
      phoneNumber: ""
    };
  },
  created() {
    this.host = this.$root.url();
    if (localStorage.getItem("phone")) {
      this.$router.push({ name: "Home" });
    }
  },
  methods: {
    passwordRecovery: function() {
      let vm = this;
      $("#phoneNumber").hide();
      let phone = this.phoneNumber;
      console.log("phone :", phone);
      if (phone !== "") {
        $("#errorMessage").html("<p>validation successful</p>");
        const type = "forgotPassword";
        const path = "forgotPassword";
        const url = `${this.host}/${path}?phone=${phone}&type=${type}`;
        axios
          .get(url)
          .then(function(response) {
            if (response.data.success === true) {
              $("#phoneNumber").show();
              $("#errorMessage").html("successfully send otp");
              localStorage.setItem("temporaryPhoneStore", phone);
              vm.$router.push({ name: "Otp" });
              return true;
            } else {
              $("#phoneNumber").show();
              $("#errorMessage").html(response.data.messages);
              return true;
            }
          })
          .catch(() => {
            $("#phoneNumber").show();
            $("#errorMessage").html(
              "<p>something goes to wrong,please try again later</p>"
            );
            return false;
          });
      } else {
        $("#phoneNumber").show();
        $("#errorMessage").html("<p>please fill up phone number field</p>");
        return false;
      }
    }
  }
};
</script>

<style scoped></style>
